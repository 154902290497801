import { Fragment } from "react";
import styled from "styled-components";
import { useSupport } from "../hooks/useSupport";

export const ChatProvider = ({ children }) => {
  const { data } = useSupport()

  return (
    <Fragment>
      {children}
      <ChatPopup whatsapp={data?.whatsapp} telegram={data?.telegram} />
    </Fragment>
  );
};

const Wrapper = styled.div`
  bottom: 14px;
  left: 16px;
  padding: 7px;
  opacity: ${({ show }) => show ? 1 : 0};
  transition: all 0.5s ease 2s;
  box-sizing: border-box;
  text-align: left;
  position: fixed;
  z-index: 9916000161;
  display: flex;
  align-items: center;
  gap: 16px;
`

const Icon = styled.a`
  flex-shrink: 0;
  width: 44px;
  height: 44px;
  order: 1;
  padding: 5px;
  box-sizing: border-box;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  box-shadow: rgb(0 0 0 / 40%) 2px 2px 6px;
  top: ${({ href }) => !!href ? "0px" : "200px"};
  transition: all 0.5s ease 0s;
  transition-delay: ${({ delay }) => `${delay}ms`};
  position: relative;
  z-index: 200;
  display: block;
  border: 0px;
  background-color: ${({ bg }) => bg};
`

function ChatPopup({ whatsapp, telegram }) {
  return (
    <Wrapper show={true}>
      <Icon bg="#4dc247" target="_blank" href={`https://wa.me/${whatsapp}`}>
        <svg
          viewBox="0 0 32 32"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          style={{
            width: "100%",
            height: "100%",
            fill: "rgb(255, 255, 255)",
            stroke: "none"
          }}
        >
          <path d="M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z" />
        </svg>
      </Icon>
      <Icon bg="rgb(0, 136, 204)" delay={100} target="_blank" href={`https://telegram.me/${telegram}`}>
        <svg
          viewBox="0 0 32 32"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          style={{
            width: "100%",
            height: "100%",
            fill: "rgb(255, 255, 255)",
            stroke: "none"
          }}
        >
          <path
            d="M15.02 20.814l9.31-12.48L9.554 17.24l1.92 6.42c.225.63.114.88.767.88l.344-5.22 2.436 1.494z"
            opacity=".6"
          />
          <path
            d="M12.24 24.54c.504 0 .727-.234 1.008-.51l2.687-2.655-3.35-2.054-.344 5.22z"
            opacity=".3"
          />
          <path d="M12.583 19.322l8.12 6.095c.926.52 1.595.25 1.826-.874l3.304-15.825c.338-1.378-.517-2.003-1.403-1.594L5.024 14.727c-1.325.54-1.317 1.29-.24 1.625l4.98 1.58 11.53-7.39c.543-.336 1.043-.156.633.214" />
        </svg>
      </Icon>
    </Wrapper>
  )
}
